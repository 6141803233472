import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Categories from '../components/Categories'
import SectionNews from '../components/Sections/SectionNews'
import SEO from '../components/seo'

const News = props => {
  const news = _.get(props, 'data.allContentfulNews.edges')
  return (
    <Layout location={props.location}>
      <SEO
        title="Noticias, AS News"
        description="Las noticias más actualizadas de México con categorías como administración, contabilidad, fiscal, emprendimiento, marketing y mucho más. "
        url={props.location.href}
      />
      <Categories
        prefix="/noticias"
        filterCategories={props.pageContext.categories}
      />
      <SectionNews
        news={news}
        {...props.pageContext}
        location={props.location}
      />
    </Layout>
  )
}

export default News

export const NewsQuery = graphql`
  query NewsFilterListNews($categories: [String], $skip: Int!, $limit: Int!) {
    allContentfulNews(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { elemMatch: { slug: { in: $categories } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "D/MM/YY")
          heroImage {
            fluid(maxWidth: 1200, maxHeight: 672) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
