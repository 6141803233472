import styled from 'styled-components'
import { BP_SMALLEST } from '../../styles/theme'

const ContentDescription = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  @media only screen and (max-width: ${BP_SMALLEST}) {
    flex-direction: column;
  }
`

const CustomTitle = styled.h2`
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ShortDescription = styled.div`
  max-height: 110px;
  overflow: hidden;
  & > p {
    &:first-child {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    display: none;
  }
`

export { CustomTitle, ContentDescription, ShortDescription }
