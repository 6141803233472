import React from 'react'
import { navigate } from 'gatsby'

import Section from '../Common/Section'
import Pagination from '../Pagination'
import ShareLinks from '../ShareLinks'

import {
  CustomTitle,
  ContentDescription,
  ShortDescription,
} from './SectionNews.styled'
import {
  CardCellNews,
  CardDescription,
  CardImage,
} from './SectionHomeNews.styled'

const SectionNews = ({ news, page, limit, total, location }) => (
  <Section>
    <div style={{ margin: '5rem 0' }}>
      {!news ? (
        <div style={{ minHeight: '400px' }}>No hay resultados</div>
      ) : (
        news.map(({ node }, index) => (
          <CardCellNews
            key={node.slug}
            horizontal="true"
            onClick={() => navigate(`/noticias/${node.slug}`)}
          >
            <CardImage
              alt={node.title}
              fluid={node.heroImage.fluid}
              horizontal="true"
            />
            <CardDescription>
              <ContentDescription>
                <div style={{ maxWidth: '90%', margin: '0 1rem' }}>
                  <CustomTitle style={{ margin: 0 }}>{node.title}</CustomTitle>
                  <ShortDescription
                    dangerouslySetInnerHTML={{
                      __html: node.description.childMarkdownRemark.html,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <span>{node.publishDate}</span>
                  <ShareLinks
                    href={`${location.origin}/noticias/${node.slug}`}
                  />
                </div>
              </ContentDescription>
            </CardDescription>
          </CardCellNews>
        ))
      )}
      <Pagination
        current={page}
        pageSize={limit}
        total={total}
        location={location}
      />
    </div>
  </Section>
)

export default SectionNews
